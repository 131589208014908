<template>
    <section class="container-fluid ">
        <div class="row" data-aos="fade-up">
            <div class="col-12 ">
                <div class="row d-none d-md-flex">
                    <div class="col-12 p-5" >
                        <h3 class="title-subtitle text-center bold">{{$store.state.lang == 'es' ? 'Espejos del Subconsciente' : 'Mirrors of the Subconscious'}}</h3>
                    </div>
                </div>
                <ExclusiveCollection />

                <div class="row d-none d-md-flex">
                    <div class="col-12 p-5" >
                        <h3 class="title-subtitle text-center bold">{{$store.state.lang == 'es' ? 'Obras de Estudio' : 'Studio Artworks'}}</h3>
                    </div>
                </div>
                <StudioCollection />
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import ExclusiveCollection from './ExclusiveCollection.vue'
import StudioCollection from './StudioCollection.vue'
export default {
    name: 'FigurativeComponent',
    components:{ExclusiveCollection,StudioCollection},
    data(){
        return{
            col1:{},
            col2:{},
            col3:{},
            all:{},
        }
    },
    created(){
        this.getArtworks()
    },
  
    methods:{
        async getArtworks(){
            await axios.get(this.$url+'/figurative',{
                headers: {'Content-Type' : 'application/json'}
            })
            .then((res)=>{
                this.col1 = res.data.col1
                this.col2 = res.data.col2
                this.col3 = res.data.col3
                this.all = res.data.all
            }).catch((err)=>{
                console.log(err)
            })
        },
        
    },
    
}
</script>